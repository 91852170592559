<template>
  <div>
    <div class="title tc">入会流程</div>
    <div class="menu clearfix mainWidth">
      <div
        class="fl"
        :class="isActive == 1 ? 'isActive cursor' : ''"
        @click="toPath(1)"
      >
        01 填写入会信息
      </div>
      <div class="fl" :class="isActive == 2 ? 'isActive cursor' : ''">
        02 内部审核
      </div>
      <div
        class="fl"
        :class="isActive == 3 ? 'isActive cursor' : ''"
        @click="toPath(3)"
      >
        03 缴纳会费
      </div>
      <div class="fl" :class="isActive == 4 ? 'isActive cursor' : ''">
        04 颁发会员证书
      </div>
    </div>
    <div class="shipData mainWidth">
      <shipFlow></shipFlow>
    </div>
    <div class="pay">
      <el-dialog title="缴纳会费" :visible.sync="payVisible" width="416px">
        <div class="companyInfo">
          <div>公司名称：<span>上海市国际货运代理行业协会</span></div>
          <div>开户银行：<span>中国银行上海市大柏树支行</span></div>
          <div>银行账号：<span class="mainColor">455959228932</span></div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="payVisible = false" size="small">取 消</el-button>
          <el-button type="danger" @click="payVisible = false" size="small"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="300px">
      <div>
        <div class="openTitle">
          暂无企业信息, 请到控制台-企业设置 完善您的公司信息
        </div>
        <div class="tc open">
          完善企业信息<i class="el-icon-right cursor" @click="toConsole"></i>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="dialogVisible1" width="350px">
      <div>
        <div class="openTitle">您不是企业管理员，请联系企业管理员申请入会</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import shipFlow from "~xif/components/index/memberShip/flow";
export default {
  components: { shipFlow },
  data() {
    return {
      isActive: 1,
      payVisible: false,
      dialogVisible: false,
      dialogVisible1: false,
      // activeImg: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/step1.png",
      // noActiveImg:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/step.png",
    };
  },
  methods: {
    async getList() {
      try {
        let result = await this.$store.dispatch("API_index/companyVipInfo", {
          company_id: this.USER_INFO.company_id,
          source: this.PJSource,
        });
        if (result.success) {
          if (JSON.stringify(result.data) == "{}") {
            // this.$router.push("/memberShip/applyFor");
            this.isActive = 1;
          } else {
            this.filterStatus(result.data);
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    filterStatus(val) {
      if (val.audit_status === 0) {
        this.isActive = 2;
      } else if (
        (val.audit_status == 1 && val.booked_status === 0) ||
        val.status == 2
      ) {
        this.isActive = 3;
        this.payVisible = true;
      } else if (val.audit_status == 1 && val.booked_status === 1) {
        this.isActive = 4;
      } else {
        this.isActive = 1;
      }
    },
    toPath(val) {
      if (this.isActive == 1 && val == 1) {
        if(!this.IS_LOGIN){
          this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN")
        }else{
          if (this.USER_INFO.company_bind_status) {
            if (this.USER_INFO.is_admin) {
              console.log('this.USER_INFO.is_admin',this.USER_INFO.is_admin,this.USER_INFO.id);
              this.$router.push({ path: "/memberShip/applyFor" });
            } else {
              this.dialogVisible1 = true;
            }
          } else {
            this.dialogVisible = true;
          }
        }
      } else if (this.isActive == 3 && val == 3) {
        this.payVisible = true;
      } else {
      }
    },
    toConsole() {
      this.$router.push({ path: "/console/company/basic" });
      // this.$router.push({
      //   path: "/memberCenter",
      //   query: {
      //     url: "company/basic",
      //   },
      // });
    },
  },
  created() {
    this.getList();
  },
};
</script>
<style lang="less" scoped>
.title {
  font-size: 21px;
  margin-bottom: 24px;
}
.mainWidth {
  width: 800px;
  margin: 0 auto;
}
.menu {
  position: relative;
  height: 40px;
  margin-bottom: 40px;
  div {
    padding: 0 45px 0 40px;
    height: 40px;
    font-size: 16px;
    background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/step.png")
      no-repeat;
    background-size: 100% 100%;
    color: #ffffff;
    line-height: 40px;
    // margin-left: -5px;
    &:nth-child(1) {
      z-index: 105;
      position: absolute;
      left: 0;
    }
    &:nth-child(2) {
      position: absolute;
      left: 187px;
      z-index: 104;
    }
    &:nth-child(3) {
      z-index: 103;
      position: absolute;
      left: 343px;
    }
    &:nth-child(4) {
      z-index: 102;
      position: absolute;
      left: 496px;
    }
  }
  .isActive {
    background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/step1.png")
      no-repeat;
    background-size: 100% 100%;
  }
}
.companyInfo {
  width: 270px;
  margin: 0 auto;
  div {
    margin-bottom: 24px;
    span {
      font-weight: bold;
    }
  }
}
.openTitle {
  font-weight: bold;
}
.open {
  margin: 20px 0 0;
  i {
    color: #ff5155;
    margin-left: 5px;
    border: 1px solid #ff5155;
    border-radius: 50%;
  }
}
</style>