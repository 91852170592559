<template>
  <div class="flow">
    <div class="standard clearfix">
      <div class="fl left">
        <div class="hearder tc">会费标准</div>
        <div class="main">
          <div
            class="item clearfix"
            v-for="(item, index) in parseList"
            :key="index"
          >
            <div class="fl">{{ item.text }}</div>
            <div class="fr mainColor">{{ item.parse }}</div>
          </div>
        </div>
      </div>
      <div class="fr right">
        <div class="title">入会指南</div>
        <div class="course">
          <div v-for="(it, index) in flowList" :key="index" class="item">
            <div class="flowItem">{{ it.title }}</div>
            <div>{{ it.explain }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="equity">
      <div class="title">会员权益</div>
      <div class="clearfix">
        <div class="content left fl">
          <div>1、有本会选举权、被选举权和表决权</div>
          <div>2、对本会的工作提出建议，进行批评、监督</div>
          <div>3、优先得到协会的资料及提供的服务</div>
          <div>4、优先参加协会组织的有关活动</div>
        </div>
        <div class="fr">
          <el-image :src="flowImg" class="img"></el-image>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      parseList: [
        {
          text: "会员单位",
          parse: "2000元/年",
        },
        {
          text: "理事单位",
          parse: "4000元/年",
        },
        {
          text: "副会长单位",
          parse: "10000元/年",
        },
        {
          text: "会长单位",
          parse: "20000元/年",
        },
      ],
      flowList: [
        {
          title: "1、提交申请",
          explain: "有意向的单位在线填写入会申请，且必须上传营业执照",
        },
        {
          title: "2、内部审核",
          explain:
            "我会收到申请入会的信息之后，会在1-3个工作日完成申请单位资料的审核工作，并提出是否同意其入会的意见，报协会领导审批",
        },
        // {
        //   title: "3、会领导批准",
        //   explain: "经会长办公会研究，做出同意入会的决定",
        // },
        {
          title: "3、颁发会员证书",
          explain: "我会向新入会单位颁发会员证书",
        },
      ],
      flowImg: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/ship1.png",
    };
  },
};
</script>
<style lang="less" scoped>
.flow {
  .standard {
    height: 346px;
    .left {
      width: 318px;
      border: 1px solid #2152d4;
      border-radius: 11px;
      .hearder {
        background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/ship.png") no-repeat;
        background-size: 100%;
        height: 117px;
        color: #ffffff;
        font-size: 21px;
        padding-top: 25px;
      }
      .main {
        border-top: none;
        height: 228px;
        padding: 10px 45px 44px 45px;
        .item {
          margin: 24px 0;
          font-size: 16px;
        }
      }
    }
    .right {
      width: 460px;
      .title {
        font-size: 21px;
        padding-top: 28px;
        font-weight: 500;
      }
      .course {
        .item {
          margin: 22px 0;
          .flowItem {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
  .equity {
    height: 200px;
    margin-top: 60px;
    .left {
      width: 318px;
    }
    .title {
      font-size: 21px;
    }
    .content {
      margin-top: 30px;
      div {
        margin-top: 20px;
        font-size: 16px;
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
    .img {
      margin-top: 20px;
    }
  }
}
</style>